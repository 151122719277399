// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-artificial-turf-jsx": () => import("./../../../src/pages/artificial-turf.jsx" /* webpackChunkName: "component---src-pages-artificial-turf-jsx" */),
  "component---src-pages-clearance-and-hedges-jsx": () => import("./../../../src/pages/clearance-and-hedges.jsx" /* webpackChunkName: "component---src-pages-clearance-and-hedges-jsx" */),
  "component---src-pages-composite-decking-installation-jsx": () => import("./../../../src/pages/composite-decking-installation.jsx" /* webpackChunkName: "component---src-pages-composite-decking-installation-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-developers-jsx": () => import("./../../../src/pages/developers.jsx" /* webpackChunkName: "component---src-pages-developers-jsx" */),
  "component---src-pages-fencing-jsx": () => import("./../../../src/pages/fencing.jsx" /* webpackChunkName: "component---src-pages-fencing-jsx" */),
  "component---src-pages-grounds-maintenance-jsx": () => import("./../../../src/pages/grounds-maintenance.jsx" /* webpackChunkName: "component---src-pages-grounds-maintenance-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-lawn-installation-jsx": () => import("./../../../src/pages/lawn-installation.jsx" /* webpackChunkName: "component---src-pages-lawn-installation-jsx" */),
  "component---src-pages-patio-and-decking-jsx": () => import("./../../../src/pages/patio-and-decking.jsx" /* webpackChunkName: "component---src-pages-patio-and-decking-jsx" */)
}

